import React from 'react';
import Swiper from 'react-id-swiper';
import { Link } from 'gatsby';
import 'swiper/css/swiper.css';
import blogTwo1 from '../assets/images/2024/maryland-luth/16.jpeg';
import blogTwo2 from '../assets/images/2024/senate-committee-commends-mgt/15.jpeg';
import blogTwo3 from '../assets/images/2024/4th-annual-conference/1.jpeg';
import blogTwo4 from '../assets/images/2024/marks-years-six-project/11.jpeg';
import blogTwo5 from '../assets/images/1-copy.jpg';
import blogTwo6 from '../assets/images/2024/luth-surgery-oesophagus/1.jpg';
import blogTwo7 from '../assets/images/2024/lagoon-partners/1.jpeg';
import blogTwo9 from '../assets/images/2023/luth-gets-new-cmd/01.jpeg';
import blogTwo10 from '../assets/images/2023/luth-gets-new-cmd/05.jpeg';
import blogTwo12 from '../assets/images/luth-logo-bg.jpg';
import blogTwo16 from '../assets/images/2024/directorofadmin/admin.jpg';
import blogTwo8 from '../assets/images/2024/ground-round-diabetis-melitus/11.jpeg';
import blogTwo11 from '../assets/images/cmac-thumb.jpg';
import blogTwo14 from '../assets/images/2024/luth-pamela/4.jpeg';
import blogTwo22 from '../assets/images/2024/dental-children/10.jpeg';
import blogTwo15 from '../assets/images/2024/international-scientific-conference/19.jpeg';
import b4 from '../assets/images/socialmedia_tech_14.jpg';

const BlogTwo = () => {
  const params = {
    slidesPerView: 3,
    loop: true,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 7000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },

    // Responsive breakpoints
    breakpoints: {
      1024: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 3,
      },
      640: {
        slidesPerView: 1,
      },
      320: {
        slidesPerView: 1,
      },
    },
  };

  return (
    <section className='blog-two' style={{ height: 700 }}>
      <div className='container'>
        <div className='block-title text-center'>
          <h2 className='block-title__title'>
            Our latest news <br />& articles
          </h2>
        </div>
        <div className='blog-two__carousel'>
          <Swiper {...params}>
            {/* <div className='item'>
            
            <div
              className='blog-two__single'
              style={{
                backgroundImage: `url(${blogTwo12})`,
                width: 370,
                height: 367,
              }}
            >
              
              <div className='blog-two__inner'>                  
                {/* <div className='blog-two__meta'>
                  <a href='#none'>by Department of Medicine</a>
                </div> */}
            {/* <h3 className='blog-two__title'>
                  <Link to='/news/exam-date-nursing'>
                    SCHOOL OF NURSING ENTRANCE EXAM (UPDATED)
                  </Link>
                </h3>
              </div>
            </div>
          </div> */}

            

            <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo8})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  <div className='blog-two__meta'>
                    <a href='#none'>September 11, 2024</a>
                  </div>
                  <h3 className='blog-two__title'>
                    <Link to='/news/cmd-transform'>
                      CMD nominated as one of the most outstanding CMDs in Nigeria
                    </Link>
                  </h3>
                  <span
                    style={{
                      color: '#fff',
                    }}
                  >
                    Lagos, Nigeria
                  </span>
                </div>
              </div>
            </div>

            <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo14})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  <div className='blog-two__meta'>
                    <a href='#none'>September 2, 2024</a>
                  </div>
                  <h3 className='blog-two__title'>
                    <Link to='/news/luth-pamela'>
                      LUTH Welcomes Dr. Pamela Agbamu Back from Isreal
                    </Link>
                  </h3>
                  <span
                    style={{
                      color: '#fff',
                    }}
                  >
                    Lagos, Nigeria
                  </span>
                </div>
              </div>
            </div>

            <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo1})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  {/* <div className='blog-two__meta'>
                  <a href='#none'>by Department of Medicine</a>
                </div> */}
                  <h3 className='blog-two__title'>
                    <Link to='/news/maryland-luth'>
                      University of Maryland School of Medicine Collaborates
                      with LUTH
                    </Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${b4})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  {/* <div className='blog-two__meta'>
                  <a href='#none'>by Department of Medicine</a>
                </div> */}
                  <h3 className='blog-two__title'>
                    <Link to='/news/irredeemable'>
                      RE: Irredeemable Country
                    </Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo16})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  {/* <div className='blog-two__meta'>
                  <a href='#none'>by Department of Medicine</a>
                </div> */}
                  <h3 className='blog-two__title'>
                    <Link to='/news/directorofadmin'>
                      MS. OMOLOLA OLUBUKUNOLA FAKEYE (JP) APPOINTED AS DIRECTOR
                      OF ADMINISTRATION IN LAGOS UNIVERSITY TEACHING HOSPITAL.
                    </Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo22})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  {/* <div className='blog-two__meta'>
                  <a href='#none'>by Department of Medicine</a>
                </div> */}
                  <h3 className='blog-two__title'>
                    <Link to='/news/dental-children'>
                      LUTH celebrates Children's Day
                    </Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo11})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  {/* <div className='blog-two__meta'>
                  <a href='#none'>by Department of Medicine</a>
                </div> */}
                  <h3 className='blog-two__title'>
                    <Link to='/news/cmac'>
                      DR. AYODEJI OLUWOLE APPOINTED AS CHAIRMAN, MEDICAL
                      ADVISORY COMMITTEE
                    </Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo15})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  {/* <div className='blog-two__meta'>
                  <a href='#none'>by Department of Medicine</a>
                </div> */}
                  <h3 className='blog-two__title'>
                    <Link to='/news/international-scientific-conference'>
                      LUTH Hosts International Scientific Conference on
                      Interventional Cardiology
                    </Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo3})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  {/* <div className='blog-two__meta'>
                  <a href='#none'>by Department of Medicine</a>
                </div> */}
                  <h3 className='blog-two__title'>
                    <Link to='/news/4th-annual-conference'>
                      The 4th LUTH Scientific Annual Conference:Advancing
                      Cardiovascular Care.
                    </Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo2})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  {/* <div className='blog-two__meta'>
                  <a href='#none'>by Department of Medicine</a>
                </div> */}
                  <h3 className='blog-two__title'>
                    <Link to='/news/senate-committee-commends-mgt'>
                      Senate Committee on Health Commends LUTH Management.
                    </Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo5})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  {/* <div className='blog-two__meta'>
                    <a href='#none'>by Community Health Department</a>
                  </div> */}
                  <h3 className='blog-two__title'>
                    <Link to='/news/school-app-open'>
                      📢 Admission Alert: Applications Now Open for Lagos
                      University Teaching Hospital (LUTH) 📝
                    </Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo8})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  {/* <div className='blog-two__meta'>
                  <a href='#none'>by Department of Medicine</a>
                </div> */}
                  <h3 className='blog-two__title'>
                    <Link to='/news/ground-round-diabetis-melitus'>
                      LAGOS UNIVERSITY TEACHING HOSPITAL SPECIAL GRAND ROUND
                    </Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo4})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  {/* <div className='blog-two__meta'>
                  <a href='#none'>by Department of Medicine</a>
                </div> */}
                  <h3 className='blog-two__title'>
                    <Link to='/news/marks-years-six-project'>
                      LUTH CMD MARKS A YEAR IN OFFICE: COMMISSIONS SIX PROJECTS
                    </Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo6})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  {/* <div className='blog-two__meta'>
                    <a href='#none'>by Community Health Department</a>
                  </div> */}
                  <h3 className='blog-two__title'>
                    <Link to='/news/luth-surgery-oesophagus'>
                      LUTH PERFORMS SURGERY ON 13-DAY OLD TO FREE OESOPHAGUS
                    </Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo12})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  <div className='blog-two__meta'>
                    <a href='#none'>by Chairman Medical Advisory Committee</a>
                  </div>
                  <h3 className='blog-two__title'>
                    <Link to='/news/pressrelease'>
                      Re: How we lost a good man to Nigeria’s terrible
                      Healthcare System
                    </Link>
                  </h3>
                </div>
              </div>
            </div>

            <div
              className='blog-two__single'
              style={{
                backgroundImage: `url(${blogTwo7})`,
                width: 370,
                height: 367,
              }}
            >
              <div className='blog-two__inner'>
                {/* <div className='blog-two__meta'>
                    <a href='#none'>by Department of Medicine</a>
                  </div> */}
                <h3 className='blog-two__title'>
                  <Link to='/news/lagoon-partners'>
                    LAGOS UNIVERSITY TEACHING HOSPITAL PARTNERS LAGOON HOSPITAL
                    TO ESTABLISH A CARDIAC CENTER
                  </Link>
                </h3>
              </div>
            </div>

            {/* <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo14})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  <div className='blog-two__meta'>
                  <a href='#none'>by Department of Medicine</a>
                </div>
                  <h3 className='blog-two__title'>
                    <Link to='/news/luth-ground-round-jan24-news'>
                      LUTH HOSPITAL GRAND ROUND: TO DIALYZE OR NOT TO DIALYZE,
                      WHAT IS THE EVIDENCE?
                    </Link>
                  </h3>
                </div>
              </div>
            </div> */}

            {/* <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo10})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  <div className='blog-two__meta'>
                  <a href='#none'>by Department of Medicine</a>
                </div>
                  <h3 className='blog-two__title'>
                    <Link to='/news/program-update'>
                      HOSPITAL GRAND ROUND (NOTICE)
                    </Link>
                  </h3>
                </div>
              </div>
            </div> */}

            {/* <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo6})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  <div className='blog-two__meta'>
                    <a href='#none'>
                      by Head, Schools Administration Department
                    </a>
                  </div>
                  <h3 className='blog-two__title'>
                    <Link to='/news/application-extension'>
                      NOTICE OF CLOSURE OF SALE OF FORMS FOR 2023/24 ADMISSIONS
                    </Link>
                  </h3>
                </div>
              </div>
            </div> */}

            <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo9})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  {/* <div className='blog-two__meta'>
                  <a href='#none'>by Department of Medicine</a>
                </div> */}
                  <h3 className='blog-two__title'>
                    <Link to='/news/luth-gets-new-cmd'>
                      LUTH GETS A NEW CMD
                    </Link>
                  </h3>
                </div>
              </div>
            </div>

            {/* <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo1})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  <div className='blog-two__meta'>
                  <a href='#none'>by Department of Medicine</a>
                </div>
                  <h3 className='blog-two__title'>
                    <Link to='/news/application'>
                      Disclaimer: Beware of Fraudsters
                    </Link>
                  </h3>
                </div>
              </div>
            </div> */}

            {/* <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${b4})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  <div className='blog-two__meta'></div>
                  <h3 className='blog-two__title'>
                    <Link to='/news/oncology-workshop'>
                      5 days Interactive Gynaecologic Oncology Workshop
                    </Link>
                  </h3>
                </div>
              </div>
            </div> */}
            {/* <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo8})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  <div className='blog-two__meta'>
                    <a href='#none'>by Community Health Department</a>
                  </div>
                  <h3 className='blog-two__title'>
                    <Link to='/news/head-of-service'>
                      THE HEAD OF SERVICE COMMISSION THREE LEGACY PROJECTS
                    </Link>
                  </h3>
                </div>
              </div>
            </div> */}
            {/* <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo4})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  <div className='blog-two__meta'>
                    <a href='#none'>by Community Health Department</a>
                  </div>
                  <h3 className='blog-two__title'>
                    <Link to='/news/sickle-cell'>
                      THE SCFN­ LUTH POST STEM CELL TRANSPLANT CLINIC
                    </Link>
                  </h3>
                </div>
              </div>
            </div> */}

            {/* <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo1})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  <div className='blog-two__meta'>
                    <a href='#none'>by Department of Medicine</a>
                  </div>
                  <h3 className='blog-two__title'>
                    <Link to='/news/disclaimer'>Disclaimer: Fake News</Link>
                  </h3>
                </div>
              </div>
            </div> */}

            {/* <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo2})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  <div className='blog-two__meta'>
                    <a href='#none'>by Department of Medicine</a>
                  </div>
                  <h3 className='blog-two__title'>
                    <Link to='/news/capital-projects-execution'>
                      NASS scores LUTH high in capital projects execution
                    </Link>
                  </h3>
                </div>
              </div>
            </div>

            <div className='item'>
              <div
                className='blog-two__single'
                style={{
                  backgroundImage: `url(${blogTwo3})`,
                  width: 370,
                  height: 367,
                }}
              >
                <div className='blog-two__inner'>
                  <div className='blog-two__meta'>
                    <a href='#none'>by Community Health Department</a>
                  </div>
                  <h3 className='blog-two__title'>
                    <Link to='/news/comm-health-medical-outreach'>
                      Medical Outreach to Mashewele Community
                    </Link>
                  </h3>
                </div>
              </div>
            </div> */}

            {/* <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo2})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    <span>25</span>
                    Jul
                  </a>
                  <div className="blog-two__meta">
                    <a href="#none">by Admin</a>
                    <a href="#none">3 Comments</a>
                  </div>
                  <h3 className="blog-two__title">
                    <a href="news-details.html">
                      Get a tips to develop a quality education
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo3})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    <span>25</span>
                    Jul
                  </a>
                  <div className="blog-two__meta">
                    <a href="#none">by Admin</a>
                    <a href="#none">3 Comments</a>
                  </div>
                  <h3 className="blog-two__title">
                    <a href="news-details.html">
                      Learn variety of programs and courses
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo1})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    <span>25</span>
                    Jul
                  </a>
                  <div className="blog-two__meta">
                    <a href="#none">by Admin</a>
                    <a href="#none">3 Comments</a>
                  </div>
                  <h3 className="blog-two__title">
                    <a href="news-details.html">
                      Summer high school journalism camp
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo2})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    <span>25</span>
                    Jul
                  </a>
                  <div className="blog-two__meta">
                    <a href="#none">by Admin</a>
                    <a href="#none">3 Comments</a>
                  </div>
                  <h3 className="blog-two__title">
                    <a href="news-details.html">
                      Get a tips to develop a quality education
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo3})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    <span>25</span>
                    Jul
                  </a>
                  <div className="blog-two__meta">
                    <a href="#none">by Admin</a>
                    <a href="#none">3 Comments</a>
                  </div>
                  <h3 className="blog-two__title">
                    <a href="news-details.html">
                      Learn variety of programs and courses
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo1})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    <span>25</span>
                    Jul
                  </a>
                  <div className="blog-two__meta">
                    <a href="#none">by Admin</a>
                    <a href="#none">3 Comments</a>
                  </div>
                  <h3 className="blog-two__title">
                    <a href="news-details.html">
                      Summer high school journalism camp
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo2})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    <span>25</span>
                    Jul
                  </a>
                  <div className="blog-two__meta">
                    <a href="#none">by Admin</a>
                    <a href="#none">3 Comments</a>
                  </div>
                  <h3 className="blog-two__title">
                    <a href="news-details.html">
                      Get a tips to develop a quality education
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="blog-two__single"
                style={{ backgroundImage: `url(${blogTwo3})` }}
              >
                <div className="blog-two__inner">
                  <a href="news-details.html" className="blog-two__date">
                    <span>25</span>
                    Jul
                  </a>
                  <div className="blog-two__meta">
                    <a href="#none">by Admin</a>
                    <a href="#none">3 Comments</a>
                  </div>
                  <h3 className="blog-two__title">
                    <a href="news-details.html">
                      Learn variety of programs and courses
                    </a>
                  </h3>
                </div>
              </div>
            </div> */}
          </Swiper>
        </div>
      </div>
    </section>
  );
};
export default BlogTwo;
